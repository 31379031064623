import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './pages/loginPage.js'
import LoginSuccess from './pages/loginSuccessPage.js'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/login_success" element={<LoginSuccess/>}/>
      </Routes>
    </Router>
  );
}

export default App;