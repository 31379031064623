import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useNavigate } from 'react-router-dom';

const supabase = createClient(
  process.env.SUPABASE_URL,
  process.env.SUPABASE_KEY
);

function Login() {
  const navigate= useNavigate();

  // TODO : this does not work
  supabase.auth.onAuthStateChange(async (event) => {
    if(event === "SIGNED_IN")
    {
      navigate("/login_success");
    }
    else
    {
      navigate("/");
    }
  });

  return (
    <div className="App">
      <header className="App-header">
        <Auth
          supabaseClient={supabase}
          appearance={{theme : ThemeSupa}}
          theme="dark"
          providers={[]}
        />
      </header>
    </div>
  );
}
  
export default Login;
  