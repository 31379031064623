

function LoginSuccess() {
    return (
      <div className="App">
        <header className="App-header">
            <h1>Login Success</h1>
        </header>
      </div>
    );
  }
  
  export default LoginSuccess;
  